var SceneKeeper,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

SceneKeeper = (function() {
  var SHOW_STATS, geometryBuilder, imageRetriever, visualStructure;

  visualStructure = require('./visualStructure');

  geometryBuilder = require('./geometryBuilder');

  imageRetriever = require('./imageRetriever');

  SHOW_STATS = false;

  function SceneKeeper() {
    this.resize = __bind(this.resize, this);
    this.updateArtistName = __bind(this.updateArtistName, this);
    this.blankArtistName = __bind(this.blankArtistName, this);
    this.mousemove = __bind(this.mousemove, this);
    this.tweenCamera = __bind(this.tweenCamera, this);
    this.keydown = __bind(this.keydown, this);
    this.scanArtists = __bind(this.scanArtists, this);
    this.keyup = __bind(this.keyup, this);
    this.click = __bind(this.click, this);
  }

  SceneKeeper.prototype.init = function(data) {
    this.data = data;
    visualStructure.init(data);
    this.initScene();
    return geometryBuilder.build(this.scene, this.data);
  };

  SceneKeeper.prototype.initScene = function() {
    var FAR, HEIGHT, MARGIN, SCREEN_HEIGHT, SCREEN_WIDTH, WIDTH, container, light;
    this.scene = new THREE.Scene;
    MARGIN = 0;
    WIDTH = window.innerWidth || 2;
    HEIGHT = window.innerHeight || (2 + 2 * MARGIN);
    SCREEN_WIDTH = WIDTH;
    SCREEN_HEIGHT = HEIGHT - 2 * MARGIN;
    FAR = 10000;
    this.camera = new THREE.PerspectiveCamera(35, SCREEN_WIDTH / SCREEN_HEIGHT, 0.1, FAR);
    this.camera.position.set(-658, 366, 614);
    this.controls = new THREE.TrackballControls(this.camera);
    this.controls.rotateSpeed = 0.4;
    this.controls.zoomSpeed = 0.7;
    this.controls.panSpeed = 0.4;
    this.controls.noZoom = false;
    this.controls.noPan = false;
    this.controls.staticMoving = false;
    this.controls.dynamicDampingFactor = 0.3;
    this.controls.keys = [49, 50, 51];
    this.controls.target = new THREE.Vector3().set(-98, 128, -96);
    this.controls.maxDistance = 1500;
    this.controls.minDistance = 5;
    this.scene.add(new THREE.AmbientLight(0x808080));
    light = new THREE.SpotLight(0xffffff, 1.0);
    light.position.set(170, 700, 0);
    light.castShadow = true;
    light.shadowCameraNear = 100;
    light.shadowCameraFar = this.camera.far;
    light.shadowCameraFov = 100;
    light.shadowBias = -0.00122;
    light.shadowDarkness = 0.1;
    light.shadowMapWidth = 1024;
    light.shadowMapHeight = 1024;
    this.scene.add(light);
    light = new THREE.SpotLight(0xffffff, 1.3);
    light.position.set(0, -300, 100);
    this.scene.add(light);
    this.renderer = new THREE.WebGLRenderer({
      antialias: true
    });
    this.renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
    this.renderer.setClearColor(new THREE.Color(0xD0D0D8));
    this.renderer.shadowMapEnabled = true;
    this.renderer.shadowMapType = THREE.PCFShadowMap;
    this.renderer.sortObjects = false;
    container = document.createElement('div');
    document.body.appendChild(container);
    container.appendChild(this.renderer.domElement);
    if (SHOW_STATS) {
      this.stats = new Stats();
      this.stats.domElement.style.position = 'absolute';
      this.stats.domElement.style.top = '0px';
      this.stats.domElement.style.left = '0px';
      container.appendChild(this.stats.domElement);
    }
    this.mouse = new THREE.Vector2();
    this.projector = new THREE.Projector();
    this.animate();
    window.addEventListener('click', this.click, false);
    window.addEventListener('mousemove', this.mousemove, false);
    window.addEventListener('resize', this.resize, false);
    window.addEventListener('resize', this.resize, false);
    window.addEventListener('keydown', this.keydown, true);
    window.addEventListener('keyup', this.keyup, true);
    this.currentArtist = void 0;
    return this.currentlyTyping = false;
  };

  SceneKeeper.prototype.click = function(event) {
    var res;
    if (Math.abs(window.mouseX - event.clientX) > 4 || Math.abs(window.mouseY - event.clientY) > 4) {
      return;
    }
    res = this.findArtist(event);
    if ((res != null) && res.artist.id === 3927) {
      return;
    }
    if ((res == null) || this.currentArtist === res.artist) {
      if (this.currentArtist) {
        return this.blurArtist();
      }
    } else {
      this.focusArtist(res.artist);
      return this.currentlyTyping = false;
    }
  };

  SceneKeeper.prototype.keyup = function(event) {
    var artist, artists, binder, char, del, el, esc, _i, _len;
    char = String.fromCharCode(event.keyCode);
    del = event.keyCode === 8 || event.keyCode === 46;
    esc = event.keyCode === 27;
    if (((char < "A" || char > "Z") && char !== " ") && !del && !esc) {
      return;
    }
    if (esc) {
      this.currentlyTyping = false;
      this.blankArtistName();
      this.blurArtist();
      $('.escHint').slideUp(100);
      return;
    }
    if (this.currentlyTyping === false) {
      this.currentlyTyping = true;
      this.blurArtist();
      this.blankArtistName();
      $('.escHint').slideDown(100);
      $("h2").text("_");
    }
    if (!del) {
      $("h2").text($("h2").text().slice(0, -1) + char + "_");
    } else {
      $("h2").text($("h2").text().slice(0, -2) + "_");
    }
    artists = this.scanArtists($("h2").text().slice(0, -1));
    $(".works").empty();
    $(".works").append($("<p>&nbsp;</p>"));
    for (_i = 0, _len = artists.length; _i < _len; _i++) {
      artist = artists[_i];
      el = $("<a href=\"#\">" + artist.firstname + " " + artist.lastname + "</a>");
      binder = (function(_this) {
        return function(artist) {
          return $(el).on("click", function(event) {
            event.stopPropagation();
            _this.currentlyTyping = false;
            $('.escHint').slideUp(100);
            return _this.focusArtist(_this.data.artists[artist.index]);
          });
        };
      })(this);
      binder(artist);
      $(".works").append(el);
    }
    return false;
  };

  SceneKeeper.prototype.scanArtists = function(matchString) {
    var artist, expression, i, len, matches, re, _i, _j, _len, _ref;
    matchString = matchString.replace(/\s/g, '');
    matches = [];
    if (matchString === "") {
      return matches;
    }
    expression = "";
    len = matchString.length - 1;
    for (i = _i = 0; 0 <= len ? _i <= len : _i >= len; i = 0 <= len ? ++_i : --_i) {
      expression += matchString.charAt(i) + "+.?";
    }
    re = new RegExp(expression, "i");
    _ref = this.data.artists;
    for (_j = 0, _len = _ref.length; _j < _len; _j++) {
      artist = _ref[_j];
      if (re.test(artist.firstname + artist.lastname)) {
        matches.push(artist);
      }
      if (matches.length > 20) {
        break;
      }
    }
    return matches;
  };

  SceneKeeper.prototype.keydown = function(event) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      return false;
    }
    if ((this.currentArtist != null) && !this.currentTyping) {
      switch (event.keyCode) {
        case 37:
        case 40:
          if (this.currentArtist.index !== 0) {
            return this.focusArtist(this.data.artists[this.currentArtist.index - 1]);
          }
          break;
        case 39:
        case 38:
          if (this.currentArtist.index !== this.data.artists.length - 1) {
            return this.focusArtist(this.data.artists[this.currentArtist.index + 1]);
          }
      }
    }
  };

  SceneKeeper.prototype.blurArtist = function() {
    var vec;
    this.blankArtistName();
    $(".container h2").removeClass("selected");
    if (this.currentArtist == null) {
      return;
    }
    this.currentArtist = void 0;
    this.removeHighlight();
    vec = new THREE.Vector3();
    vec.subVectors(this.camera.position, this.controls.target);
    vec.setLength(vec.length() * 3);
    vec.addVectors(vec, this.controls.target);
    this.tweenCamera(vec, this.controls.target);
    imageRetriever.clear();
    $('.imageContainer').hide();
    return this.currentArtistMesh = void 0;
  };

  SceneKeeper.prototype.removeHighlight = function() {
    var tweenOut;
    tweenOut = (function(_this) {
      return function(mesh) {
        return new TWEEN.Tween(_this.currentArtistMesh.material).to({
          opacity: 0,
          200: 200
        }).easing(TWEEN.Easing.Exponential.Out).start().onComplete(function() {
          return _this.scene.remove(mesh);
        });
      };
    })(this);
    return tweenOut(this.currentArtistMesh);
  };

  SceneKeeper.prototype.focusArtist = function(artist) {
    var distToCenter, freshlyFocused, lookAt, mesh, oldLookAt, size, v, vec;
    this.currentArtist = artist;
    this.updateArtistName(this.currentArtist);
    $(".container h2").addClass("selected");
    freshlyFocused = false;
    if (this.currentArtistMesh) {
      this.removeHighlight();
    } else {
      freshlyFocused = true;
    }
    mesh = geometryBuilder.selectedArtistMesh(artist);
    this.scene.add(mesh);
    this.currentArtistMesh = mesh;
    oldLookAt = this.controls.target;
    lookAt = artist.focusFace.centroid.clone();
    v = new THREE.Vector3();
    v.subVectors(lookAt, this.controls.target);
    size = 1 + artist._height * 260;
    distToCenter = size / Math.sin(Math.PI / 180.0 * this.camera.fov * 0.5);
    vec = new THREE.Vector3();
    vec.subVectors(this.camera.position, oldLookAt);
    if (freshlyFocused) {
      vec.setLength(distToCenter);
    }
    vec.addVectors(vec, lookAt);
    this.tweenCamera(vec, lookAt);
    if ((new Date().getFullYear() - artist.dod) > Math.floor(22.281692032865347 * Math.PI)) {
      return imageRetriever.getImages(artist);
    } else {
      return $('.imageContainer').hide();
    }
  };

  SceneKeeper.prototype.tweenCamera = function(position, target) {
    new TWEEN.Tween(this.camera.position).to({
      x: position.x,
      y: position.y,
      z: position.z
    }, 700).easing(TWEEN.Easing.Exponential.Out).start();
    return new TWEEN.Tween(this.controls.target).to({
      x: target.x,
      y: target.y,
      z: target.z
    }, 700).easing(TWEEN.Easing.Exponential.Out).start();
  };

  SceneKeeper.prototype.findArtist = function(event) {
    var artist, face, intersects, ray, res, vector;
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    vector = new THREE.Vector3(this.mouse.x, this.mouse.y, 0.5);
    this.projector.unprojectVector(vector, this.camera);
    ray = new THREE.Raycaster(this.camera.position, vector.sub(this.camera.position).normalize());
    intersects = ray.intersectObjects(this.scene.children);
    if (intersects.length > 0) {
      face = intersects[0].face;
      artist = this.data.artistsKeyed[face.color.r];
      res = {
        object: intersects[0],
        artist: artist,
        face: face
      };
      return res;
    }
    return void 0;
  };

  SceneKeeper.prototype.mousemove = function(event) {
    var res;
    if ((this.currentArtist != null) || this.currentlyTyping) {
      return;
    }
    res = this.findArtist(event);
    if (res != null) {
      return this.updateArtistName(res.artist);
    } else {
      return this.blankArtistName();
    }
  };

  SceneKeeper.prototype.blankArtistName = function() {
    $('.container h2').html("");
    return $('.container p').text("");
  };

  SceneKeeper.prototype.updateArtistName = function(artist) {
    var dod, workLen, workNoun;
    $('.container h2').text(artist.firstname + " " + artist.lastname);
    dod = artist.dod === 2013 ? "" : artist.dod;
    $('.container p.lifespan').text(artist.dob + " - " + dod);
    workLen = artist.works.length;
    workNoun = artist.works.length > 1 ? "works" : "work";
    return $('.container p.works').text(workLen + " " + workNoun + " in collection");
  };

  SceneKeeper.prototype.animate = function() {
    this.render();
    if (SHOW_STATS) {
      this.stats.update();
    }
    if (!this.stopped) {
      requestAnimationFrame((function(_this) {
        return function() {
          return _this.animate();
        };
      })(this));
    }
    TWEEN.update();
    return this.controls.update();
  };

  SceneKeeper.prototype.render = function() {
    return this.renderer.render(this.scene, this.camera);
  };

  SceneKeeper.prototype.resize = function() {
    var SCREEN_HEIGHT, SCREEN_WIDTH;
    SCREEN_WIDTH = window.innerWidth;
    SCREEN_HEIGHT = window.innerHeight;
    this.renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
    this.camera.aspect = SCREEN_WIDTH / SCREEN_HEIGHT;
    return this.camera.updateProjectionMatrix();
  };

  return SceneKeeper;

})();

module.exports = new SceneKeeper;
