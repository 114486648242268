var Importer;

Importer = (function() {
  function Importer() {
    this.data = {
      artistsKeyed: {},
      works: []
    };
  }

  Importer.prototype.load = function() {
    var artistsLoaded, photographers;
    artistsLoaded = $.Deferred();
    photographers = ["Morten Thorkildsen", "Børre Høstland", "Jeanette Veiby", "Frode Larsen", "Anne Hansteen Jarre", "Dag A. Ivarsøy", "Therese Husby", "Dag Andre Ivarsøy", "Jacques Lathion", "Børre Høstland", "Andreas Harvik", "Ukjent", "Stein Jorgensen", "Øyvind Andersen", "Jaques Lathion", "Ole Henrik Storeheier", "Dag A, Ivarsøy", "Anne Hansteen", "Ukjent", "Scanned by Dag A. Ivarsøy", "Børre Høstland/ Andreas Harvik", "Annar Bjørgli", "Angela Musil-Jantjes", "Ukjent", "Stein Jørgensen", "Knut Øystein Nerdrum", "Børre Høstland", "Knut Øystein Nerdrum"];
    d3.csv("data/artists.csv", (function(_this) {
      return function(err, rows) {
        rows.forEach(function(row) {
          row.works = [];
          row.id = +row.id;
          row.gender = +row.gender;
          row.dob = +row.dob;
          row.dod = +row.dod;
          _this.data.artistsKeyed[row.id] = row;
          if (row.dob === row.dod) {
            return row.dod += 1;
          }
        });
        _this.data.artists = rows;
        return artistsLoaded.resolve();
      };
    })(this));
    artistsLoaded.then((function(_this) {
      return function() {
        return d3.csv("data/works_coded.csv", function(err, rows) {
          rows.forEach(function(row) {
            var value;
            row.artistId = +row.artistId;
            row.produced = +row.produced;
            row.acquired = +row.acquired;
            row.invalid = row.produced === 0 || row.acquired === 0;
            if (row.photographer_id !== "-1") {
              row.photographer = photographers[row.photographer_id] + " / Nasjonalmuseet";
            } else {
              row.photographer = "Nasjonalmuseet";
            }
            value = _this.data.artistsKeyed[row["artistId"]];
            if (value != null) {
              _this.data.artistsKeyed[row["artistId"]].works.push(row);
              return _this.data.works.push(row);
            }
          });
          _this.data.artists = _.sortBy(_this.data.artists, function(artist) {
            return artist.dob;
          });
          _this.data.artists.forEach(function(artist, i) {
            return artist.index = i;
          });
          _this.data.artists.forEach(function(artist) {
            return artist.works = _.sortBy(artist.works, function(work) {
              return work.produced;
            });
          });
          return _this.dataLoaded.resolve(_this.data);
        });
      };
    })(this));
    return this.dataLoaded = $.Deferred();
  };

  return Importer;

})();

module.exports = new Importer;
