var ImageRetriever,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

ImageRetriever = (function() {
  var baseURL;

  baseURL = "http://api.digitaltmuseum.no/artifact?owner=NMK-B&mapping=ESE&api.key=demo&identifier=";

  function ImageRetriever() {
    this.previousBlock = __bind(this.previousBlock, this);
    this.nextBlock = __bind(this.nextBlock, this);
    $(".imageContainer").on("mouseleave", function(event) {
      $(".zoomedImageContainer").hide();
      $(".photographer").text("");
      return $(".title").text("");
    });
    $(".navBlock .next").click(this.nextBlock);
    $(".navBlock .prev").click(this.previousBlock);
    this.maxImages = 30;
  }

  ImageRetriever.prototype.getImages = function(artist) {
    this.clear();
    this.works = artist.works;
    this.works = this.works.sort(function(a, b) {
      return a.kind < b.kind;
    });
    this.works = this.works.filter(function(a) {
      return a.imageCount > 0;
    });
    if (this.works.length === 0) {
      $('.imageContainer').hide();
      return;
    }
    this.currentOffset = 0;
    this.sheetNumber = 1;
    this.getImageBlock(this.works.slice(this.currentOffset, this.currentOffset + this.maxImages));
    this.updateDisplay();
    if (this.works.length < this.maxImages) {
      $('.navBlock').hide();
    } else {
      $('.navBlock').show();
    }
    return $('.imageContainer').show();
  };

  ImageRetriever.prototype.updateDisplay = function() {
    if (this.works.length > 0) {
      $('.navBlock p.counter').text(this.sheetNumber + " / " + (Math.ceil(this.works.length / this.maxImages)));
    } else {
      $('.navBlock p.counter').text("");
    }
    if (this.currentOffset === 0) {
      $('.navBlock .prev').addClass("deactivated");
    } else {
      $('.navBlock .prev').removeClass("deactivated");
    }
    if (this.currentOffset + this.maxImages >= this.works.length) {
      return $('.navBlock .next').addClass("deactivated");
    } else {
      return $('.navBlock .next').removeClass("deactivated");
    }
  };

  ImageRetriever.prototype.nextBlock = function(event) {
    event.stopPropagation();
    if (this.currentOffset + this.maxImages >= this.works.length) {
      return;
    }
    this.currentOffset += this.maxImages;
    this.sheetNumber += 1;
    this.clear();
    this.getImageBlock(this.works.slice(this.currentOffset, this.currentOffset + this.maxImages));
    return this.updateDisplay();
  };

  ImageRetriever.prototype.previousBlock = function(event) {
    event.stopPropagation();
    if (this.currentOffset === 0) {
      return;
    }
    this.currentOffset -= this.maxImages;
    this.sheetNumber -= 1;
    this.clear();
    this.getImageBlock(this.works.slice(this.currentOffset, this.currentOffset + this.maxImages));
    return this.updateDisplay();
  };

  ImageRetriever.prototype.getImageBlock = function(works) {
    var image, work, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = works.length; _i < _len; _i++) {
      work = works[_i];
      image = new Image();
      image.src = "data/images_lores_2/" + work.id + "_0.JPG";
      image.work = work;
      _results.push(image.addEventListener("load", function(event) {
        $(".imageContainerInner").append(this);
        this.addEventListener("click", function(event) {
          return event.stopPropagation();
        });
        return this.addEventListener("mouseenter", function(event) {
          $(".zoomedImage").attr("src", "data/images/" + this.work.id + "_0.JPG");
          $(".zoomedImageContainer").show();
          $(".photographer").text("Imaging by " + this.work.photographer);
          $(".title").text("");
          return $.get(baseURL + this.work.id, {}, (function(_this) {
            return function(xml) {
              var engTitle, n, norTitle, title;
              xml = $(xml);
              n = xml.find("dc\\:title, title");
              engTitle = norTitle = void 0;
              n.each(function(n, m) {
                var title;
                title = $(m);
                if (title.context.outerHTML.indexOf("xml:lang=\"NOR\"") >= 0) {
                  norTitle = title.text();
                }
                if (title.context.outerHTML.indexOf("xml:lang=\"ENG\"") >= 0) {
                  return engTitle = title.text();
                }
              });
              title = engTitle;
              title || (title = norTitle);
              if (title == null) {
                title = "Archive reference: " + _this.work.id;
                $(".title").addClass("onlyReference");
              } else {
                $(".title").removeClass("onlyReference");
              }
              return $(".title").text(title);
            };
          })(this));
        });
      }));
    }
    return _results;
  };

  ImageRetriever.prototype.clear = function() {
    $(".imageContainerInner").empty();
    $(".zoomedImageContainer").hide();
    $(".photographer").text("");
    $(".title").text("");
    $('.navBlock .prev').removeClass("deactivated");
    $('.navBlock .prev').removeClass("deactivated");
    return $('.navBlock p.counter').text("");
  };

  return ImageRetriever;

})();

module.exports = new ImageRetriever;
