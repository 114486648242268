var GeometryBuilder;

GeometryBuilder = (function() {
  function GeometryBuilder() {
    this.artistGeometry = new THREE.CubeGeometry(1, 1, 1);
    this.workGeometry = new THREE.PlaneGeometry(1, 40);
    this.scaleX = 400;
    this.scaleY = 40;
  }

  GeometryBuilder.prototype.selectedArtistMesh = function(artist) {
    var selectedArtistMaterial;
    selectedArtistMaterial = new THREE.MeshLambertMaterial({
      opacity: 0.70,
      wireframe: false,
      transparent: true
    });
    return this.artistMesh(artist, selectedArtistMaterial, 1.50);
  };

  GeometryBuilder.prototype.artistMesh = function(artist, texture, multiplier) {
    var mesh;
    if (multiplier == null) {
      multiplier = 0;
    }
    mesh = new THREE.Mesh(this.artistGeometry, texture);
    mesh.position.set(artist._x * this.scaleX, artist._y * this.scaleY, 0);
    mesh.scale.x = (artist._width * this.scaleX) + (artist._height * multiplier);
    mesh.scale.y = (artist._height * this.scaleY) + (artist._height * multiplier);
    mesh.scale.z = (1 + artist._height * this.scaleY * 10) + (artist._height * multiplier);
    return mesh;
  };

  GeometryBuilder.prototype.build = function(scene, data) {
    var artist, currentArtist, face, gender, geometry, line, lineMaterial, materialProperties, mesh, offset, _i, _j, _k, _len, _len1, _len2, _ref, _ref1, _ref2;
    this.scene = scene;
    this.data = data;
    this.collatedArtistGeometries = [new THREE.Geometry(), new THREE.Geometry(), new THREE.Geometry()];
    this.collatedWorkGeometry = new THREE.Geometry();
    this.data.artists.forEach((function(_this) {
      return function(artist) {
        var face, mesh, _i, _len, _ref;
        mesh = _this.artistMesh(artist);
        _ref = mesh.geometry.faces;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          face = _ref[_i];
          face.color.r = artist.id;
        }
        THREE.GeometryUtils.merge(_this.collatedArtistGeometries[artist.gender], mesh);
        return artist.works.forEach(function(work) {
          var v1, v2;
          if (!work.invalid) {
            v1 = new THREE.Vector3();
            v1.set((work._x - work._width / 2) * _this.scaleX, work._y * _this.scaleY, (mesh.scale.z / 2) + 1);
            v2 = new THREE.Vector3();
            v2.set((work._x + work._width / 2) * _this.scaleX, work._y * _this.scaleY, (mesh.scale.z / 2) + 1);
            _this.collatedWorkGeometry.vertices.push(v1);
            return _this.collatedWorkGeometry.vertices.push(v2);
          }
        });
      };
    })(this));
    materialProperties = {};
    currentArtist = 0;
    offset = 0;
    _ref = this.collatedArtistGeometries;
    for (gender = _i = 0, _len = _ref.length; _i < _len; gender = ++_i) {
      geometry = _ref[gender];
      _ref1 = geometry.faces;
      for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
        face = _ref1[_j];
        artist = face.color.r;
        if (offset === 0) {
          this.data.artistsKeyed[artist].focusFace = face;
        }
        offset += 1;
        if (currentArtist !== artist) {
          offset = 0;
          currentArtist = artist;
          this.data.artistsKeyed[artist].faces = [];
        }
        this.data.artistsKeyed[artist].faces.push(face);
      }
    }
    _ref2 = this.collatedArtistGeometries;
    for (gender = _k = 0, _len2 = _ref2.length; _k < _len2; gender = ++_k) {
      geometry = _ref2[gender];
      switch (gender) {
        case 0:
          materialProperties.color = "#346";
          break;
        case 1:
          materialProperties.color = "#3058c0";
          break;
        case 2:
          materialProperties.color = "#ff7060";
      }
      mesh = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial(materialProperties));
      mesh.material.ambient = mesh.material.color;
      mesh.material.shinyness = 1;
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      this.scene.add(mesh);
    }
    lineMaterial = new THREE.LineBasicMaterial({
      color: 0xffffff,
      opacity: 0.01,
      blending: THREE.AdditiveBlending,
      linewidth: 0.1
    });
    line = new THREE.Line(this.collatedWorkGeometry, lineMaterial, THREE.LinePieces);
    return this.scene.add(line);
  };

  GeometryBuilder.prototype.yearToFloat = function(year) {
    return (year - this.startYear) / this.endYear;
  };

  return GeometryBuilder;

})();

module.exports = new GeometryBuilder;
