var VisualStructure;

VisualStructure = (function() {
  function VisualStructure() {}

  VisualStructure.prototype.init = function(data) {
    var workHeight, workIndex;
    this.data = data;
    this.tracks = [];
    console.info("Tally");
    console.info("Number of artists: " + this.data.artists.length);
    console.info("Number of works: " + this.data.works.length);
    this.startYear = this.data.artists[0].dob;
    this.endYear = new Date().getFullYear();
    console.info("Normalizing against: " + this.startYear + " - " + this.endYear);
    this.numberOfWorks = this.data.works.length / 10;
    console.info("Allocating artist space");
    workHeight = 1 / this.numberOfWorks;
    workIndex = -0.5;
    this.data.artists.forEach((function(_this) {
      return function(artist) {
        var height, width, x;
        height = artist.works.length / _this.numberOfWorks;
        x = _this.yearToFloat(artist.dob);
        width = _this.yearToFloat(artist.dod) - x;
        artist._x = x - 0.5 + (width / 2);
        artist._y = workIndex + height / 2;
        artist._height = height;
        artist._width = width;
        artist.works.forEach(function(work, i) {
          var wHeight, wWidth, wX, wY;
          if (!work.invalid) {
            wHeight = workHeight;
            wX = _this.yearToFloat(work.produced);
            wWidth = _this.yearToFloat(work.acquired) - wX;
            wY = workIndex + (workHeight * i);
            work._x = wX - 0.5 + (wWidth / 2);
            work._y = wY + wHeight / 2;
            work._height = wHeight;
            return work._width = wWidth;
          }
        });
        return workIndex += height + workHeight * 0;
      };
    })(this));
    console.info("Done");
    return this.data;
  };

  VisualStructure.prototype.yearToFloat = function(year) {
    return (year - this.startYear) / (this.endYear - this.startYear);
  };

  return VisualStructure;

})();

module.exports = new VisualStructure;
