var $warnings, activateClass;

if (this.Hipster == null) {
  this.Hipster = {};
}

if (Hipster.Routers == null) {
  Hipster.Routers = {};
}

if (Hipster.Views == null) {
  Hipster.Views = {};
}

if (Hipster.Models == null) {
  Hipster.Models = {};
}

if (Hipster.Collections == null) {
  Hipster.Collections = {};
}

$warnings = $('.warnings');

activateClass = (function() {
  var isMobile;
  isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
  if (Detector.webgl) {
    if (!isMobile && navigator.userAgent.match(/Chrom(e|ium)/)) {
      return '.shouldWork';
    }
    return '.maybeWork';
  } else {
    if (isMobile) {
      return '.cantWorkMobile';
    }
    return '.cantWork';
  }
})();

$(function() {
  var AppView, data, importer, sceneKeeper;
  require('../lib/app_helpers');
  importer = require('./importer');
  sceneKeeper = require('./sceneKeeper');
  Hipster.Views.AppView = new (AppView = require('views/app_view'));
  Backbone.history.start({
    pushState: true
  });
  $warnings.find(activateClass).addClass('active');
  return data = importer.load().then(function(data) {
    $('.intro .button').text("Start");
    $('.intro .button').removeClass("deactivated");
    $('.intro .button').click((function(_this) {
      return function() {
        $('.intro').hide();
        $('.warnings').hide();
        sceneKeeper.init(data);
        return $('body').addClass('activated');
      };
    })(this));
    $('.showNavigation').click((function(_this) {
      return function() {
        $('.copy.overview').hide();
        return $('.copy.navigation').show();
      };
    })(this));
    return $('.showOverview').click((function(_this) {
      return function() {
        $('.copy.overview').show();
        return $('.copy.navigation').hide();
      };
    })(this));
  });
});
