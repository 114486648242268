var packHierarchy;

packHierarchy = (function() {
  function packHierarchy(data, options) {
    var genderBalance, h, m, node, nodes, offset, pack, r, root, vis, w, x, y, zoom;
    if (options == null) {
      options = {};
    }
    m = Math.min($(window).width(), $(window).height());
    w = h = r = m;
    x = d3.scale.linear().range([0, r]);
    y = d3.scale.linear().range([0, r]);
    offset = options.offset || 0;
    pack = d3.layout.pack().size([r, r]).value(function(d) {
      return d.count || 0;
    }).children(function(d) {
      return d.values;
    });
    vis = d3.select("svg").append("svg:g").attr("class", options["class"]).attr("transform", "translate(" + (w - r) / 2 + "," + (h - r) / 2 + ")");
    genderBalance = function(d) {
      var ratio;
      if ((d.values != null) || (d.countWomen == null) || (d.countWomen == null)) {
        return "#666";
      }
      ratio = d.countMen / (d.countMen + d.countWomen);
      return colorbrewer.RdBu[11][Math.floor(ratio * 11)];
    };
    node = root = data;
    nodes = pack.nodes(root);
    vis.selectAll("circle").data(nodes).enter().append("svg:circle").attr("class", function(d) {
      if (d.children) {
        return "parent";
      } else {
        return "child";
      }
    }).style("fill", function(d) {
      return genderBalance(d);
    }).attr("cx", function(d) {
      return d.x;
    }).attr("cy", function(d) {
      return d.y;
    }).attr("r", function(d) {
      return d.r;
    }).on("click", function(d) {
      return zoom(d !== node ? d : root);
    }).append("svg:title").text(function(d) {
      return d.label;
    });
    vis.selectAll("text").data(nodes).enter().append("svg:text").attr("class", function(d) {
      if (d.values) {
        return "parent";
      } else {
        return "child";
      }
    }).attr("x", function(d) {
      return d.x;
    }).attr("y", function(d) {
      return d.y;
    }).attr("dy", ".35em").attr("text-anchor", "middle").style("opacity", function(d) {
      if (d.r > 40 && d.depth === 1) {
        return 1;
      } else {
        return 0;
      }
    }).text(function(d) {
      return d.label;
    });
    zoom = function(d, i) {
      var k, t;
      console.info(d);
      k = r / d.r / 2;
      x.domain([d.x - d.r, d.x + d.r]);
      y.domain([d.y - d.r, d.y + d.r]);
      t = vis.transition().duration(750);
      t.selectAll("circle").attr("cx", function(de) {
        return x(de.x);
      }).attr("cy", function(de) {
        return y(de.y);
      }).attr("r", function(de) {
        return k * de.r;
      });
      t.selectAll("text").attr("x", function(de) {
        return x(de.x);
      }).attr("y", function(de) {
        return y(de.y);
      }).style("opacity", function(de) {
        if (k * de.r > 30 && (de.depth === d.depth + 1 || !de.values)) {
          return 1;
        } else {
          return 0;
        }
      });
      node = d;
      return d3.event.stopPropagation();
    };
    d3.select(window).on("click", function() {
      return zoom(root);
    });
  }

  return packHierarchy;

})();

module.exports = packHierarchy;
