var AppRouter, AppView, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('../lib/view');

AppRouter = require('../routers/app_router');

module.exports = AppView = (function(_super) {
  __extends(AppView, _super);

  function AppView() {
    return AppView.__super__.constructor.apply(this, arguments);
  }

  AppView.prototype.el = 'body.application';

  AppView.prototype.initialize = function() {
    var _ref;
    this.router = new AppRouter();
    return typeof Hipster !== "undefined" && Hipster !== null ? (_ref = Hipster.Routers) != null ? _ref.AppRouter = this.router : void 0 : void 0;
  };

  return AppView;

})(View);
